
import { IProductsCategoryLight } from '@/interfaces/products';
import { dispatchGetPopularProductsCategoriesList } from '@/store/products/actions';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})

export default class PopularCategoriesList extends Vue {
  public popularCategoriesList: IProductsCategoryLight[] = [];
  public categoriesColorsBg = [
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    'bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink',
    ];
  public loadingState: any = {
    page: true,
  };

  public async mounted() {
    this.popularCategoriesList = await dispatchGetPopularProductsCategoriesList(this.$store,);
    this.loadingState.page = false;
  }

  public handleChangeCategory(category) {
    this.$router.push(`/catalog/${category.codename}`);
  }

}
